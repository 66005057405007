import React from "react"
//import { Link } from "gatsby"

import SEO from "../components/seo"
import Layout from "../components/layout"
import TeamPage from "../components/team"
import PartnerPage from "../components/partners"
import Teamimage from "./../images/7ba4dd7a-5ade-4349-8d08-bc46a1289439.jpg"

const Team = () => (
  <Layout>

    <SEO title="Our team" />
    <section className="section-header text-white overlay-soft-primary" style={{backgroundImage: "url("+Teamimage+")", backgroundColor: '#cccccc', backgroundPosition: 'top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', position: 'relative'}}>
      <div className="container-fluid">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">Team & Partners</h1>
          </div>
        </div>
      </div>
    </section>
    <div>
      <div className="container pt-4">
        <div className="row justify-content-center text-center align-items-center">
          <div className="col">
            <h1 className="display-1">Our Team</h1>
          </div>
        </div>
      </div>
    </div>
    <TeamPage />
    <section>
      <div className="container">
        <div className="row justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">International advisors</h1>
          </div>
        </div>
      </div>
    </section>
    <PartnerPage />

    
  </Layout>
)

export default Team
